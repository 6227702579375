<template>
  <div class="dispose">
    <el-dialog
      title="高线厂/AB高线/驱动端水平"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <div>
        <div>
          <el-radio v-model="radio" label="1">标记误报</el-radio>
          <el-radio v-model="radio" label="2">派遣维修</el-radio>
        </div>

        <div v-if="radio == '1'">
          <div class="box flex mt-30 pos-rel">
            <div>备注：</div>
            <div class="pos-xcenter">
              <el-input
                type="textarea"
                :rows="4"
                resize="none"
                placeholder="请输入内容"
                v-model="remarks"
              >
              </el-input>
            </div>
          </div>
        </div>

        <div v-if="radio == '2'" class="mt-30">
          <el-table
            :data="tableData"
            border
            style="width: 100%"
            @selection-change="select"
          >
            <el-table-column type="selection" width="55" align="center">
            </el-table-column>
            <el-table-column prop="index" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="date" label="手机号码" align="center">
            </el-table-column>
            <el-table-column prop="name" label="部门" align="center">
            </el-table-column>
          </el-table>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["row"],
  watch: {
    row(val) {
      this.unit_id = val.unit_id;
    },
  },

  data() {
    return {
      dialogVisible: false,

      radio: "1",
      user_id: "",
      unit_id: "",
      remarks: "",
      tableData: [{}],
    };
  },

  methods: {
    //  getList(){
    //   this.$get("/backend-api/web/unit/log/mark", this.timeFrome).then(
    //       (res) => {
    //         if ((res.code = 1000)) {
    //         }
    //       }
    //     );
    // },
    getData() {
      this.$get("/backend-api/web/unit/log/mark", this.timeFrome).then(
        (res) => {
          if ((res.code = 1000)) {
            this.dialogVisible = false;
          }
        }
      );
    },
    submit() {
      if (this.radio == "1") {
        this.timeFrome = {
          user_id: this.user_id,
          unit_id: this.unit_id,
        };
        this.getData();
      } else {
        this.timeFrome = {
          unit_id: this.timeFrome.unit_id,
          user_id: this.user_id,
        };
        this.getData();
      }
    },
    select(val) {
      this.user_id = val.id;
    },
  },
};
</script>

<style lang='scss' >
.dispose {
  .box {
    width: 100%;
    height: 140px;
  }
  .el-dialog__header {
    background-color: #f5f5f5;
  }
  .el-textarea {
    width: 50%;
  }
  .el-table--border th {
    border-right: none;
    background-color: #e6f7ff;
  }
  .el-textarea__inner {
    width: 360px;
    height: 124px;
  }
}
</style>