<template>
    <div class="history">
         <el-dialog
    title="高线厂/AB高线/驱动端水平"
    :visible.sync="dialogVisible"
  >
   <div>
       
   </div>
   <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button> -->
      </span>
  </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["row"],
    data(){
        return{
            dialogVisible:false
        }
    }

}
</script>

<style lang="scss">
.history{
    .el-dialog__header {
    background-color: #f5f5f5;
  }
}
</style>