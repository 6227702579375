<template>
	<el-dialog class="dialog" title="报警值设置" :visible.sync="visiable" width="80%">
		<div v-loading="loading" class="dlalog-body">
			<el-tabs type="border-card">
				<el-tab-pane label="通用配置">
					<el-table ref="table" :data="tableData.ent" border height="500"
						:header-cell-style="{'background-color':'#f9fafc'}" row-key="id"
						:tree-props="{ children: 'measList'}" @row-click="expandChange" style="margin-bottom: 20px;">
						<el-table-column prop="name" align="center" label="监控类型" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="alarm1" align="center" label="一级报警" show-overflow-tooltip>
							<template slot-scope="scope">
								<el-input v-model="scope.row.alarm1"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="alarm2" align="center" label="二级报警" show-overflow-tooltip>
							<template slot-scope="scope">
								<el-input v-model="scope.row.alarm2"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="alarm3" align="center" label="三级报警" show-overflow-tooltip>
							<template slot-scope="scope">
								<el-input v-model="scope.row.alarm3"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="name" align="center" label="说明" show-overflow-tooltip>
						</el-table-column>
					</el-table>
				</el-tab-pane>
				<el-tab-pane label="自定义设置">
					<el-table ref="table" :data="tableData.unit" border height="500"
						:header-cell-style="{'background-color':'#f9fafc'}" row-key="id"
						:tree-props="{ children: 'children'}" @row-click="expandChange" style="margin-bottom: 20px;">
						<el-table-column prop="unitName" align="center" label="设备名称" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="measName" align="center" label="测点" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="type" align="center" label="配置方式" show-overflow-tooltip>
							<template slot-scope="scope" v-if="scope.row.alarmName">
								<el-radio-group v-model="scope.row.type">
									<el-radio :label="0">通用</el-radio>
									<el-radio :label="1">自定义</el-radio>
								</el-radio-group>
							</template>
						</el-table-column>
						<el-table-column prop="alarmName" align="center" label="监控类型" show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="alarm1" align="center" label="一级报警" show-overflow-tooltip>
							<template slot-scope="scope" v-if="scope.row.alarmName&&scope.row.type==1">
								<el-input v-model="scope.row.alarm1"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="alarm2" align="center" label="二级报警" show-overflow-tooltip>
							<template slot-scope="scope" v-if="scope.row.alarmName&&scope.row.type==1">
								<el-input v-model="scope.row.alarm2"></el-input>
							</template>
						</el-table-column>
						<el-table-column prop="alarm3" align="center" label="三级报警" show-overflow-tooltip>
							<template slot-scope="scope" v-if="scope.row.alarmName&&scope.row.type==1">
								<el-input v-model="scope.row.alarm3"></el-input>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
			<div class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="save()">保存</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				loading: false,
				tableData: []
			};
		},
		props: {
			entId: {
				type: String,
				default () {
					return "";
				}
			}
		},
		methods: {
			//打开弹窗
			open() {
				this.tableData = [];
				this.list();
			},
			//展示数据
			list() {
				this.loading = true;
				this.$get("/backend-api/set/alarm/list", {
					entId: this.entId
				}).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.tableData = res.data;
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				});
			},
			//点击某一行的回调事件
			expandChange(row, index, e) {
				if (index.label == '设备名称') {
					this.$refs.table.toggleRowExpansion(row)
				}
			},
			save() {
				this.$postData("/backend-api/set/alarm/update", this.tableData).then((res) => {
					if (res.code == 1000) {
						this.loading = false;
						this.$message.success(res.msg);
						this.$parent.getTableList();
						this.close();
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				});

			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss";
</style>
