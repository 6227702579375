<template>
  <div class="help">
    <el-dialog title="高线厂/AB高线/驱动端水平" :visible.sync="dialogVisible">
      <div>
        <div class="flex ai-center jc-between mb-20">
          <el-button>打开交易商场寻找诊断专家或维修师傅</el-button>
          <div>
            您可以把该单体设备图谱信息，共享给已下单的专家或师傅，对方即可查阅
          </div>
        </div>
        <div class="mb-20">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="time" label="下单时间" align="center">
            </el-table-column>
            <el-table-column prop="code" label="订单名称" align="center">
            </el-table-column>
            <el-table-column prop="name" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="photo" label="手机" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">              
                <el-button type="text" size="small" @click="shareChat(scope.row)">共享</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
            PS：订单取消或者订单已完成，系统将自动取消共享
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["row"],
  data() {
    return {
      dialogVisible: false,
      tableData:[
          {time:'2021.1.1 15.55',
          code:"设备诊断",
          name:'张三',
          photo:"1888784454",
          }
      ]
    };
  },
  methods:{
      shareChat(){

      }
  }
};
</script>

<style lang="scss">
.help {
  .el-dialog__header {
    background-color: #f5f5f5;
  }
  .el-table--border th{
      border-right: none;
      background-color: #e6f7ff;
  }
}
</style>