<template>
	<!-- 监控中心 -->
	<div class="monitoring flex">
		<div class="drawerBox" v-show="drawer">
			<div class="drawerBox-top">
				<div>选择测点查看</div>
				<i class="el-icon-close drawerBox-top-icon" @click="drawer = false"></i>
			</div>
			<div class="treeBox">
				<el-tree :data="treeData" ref="tree" node-key="id" :props="defaultProps" @node-click="handleNodeClick"
					style="overflow: auto;width: 280px;" :default-expanded-keys="showData"></el-tree>
			</div>
		</div>

		<el-main style="height: 100%; display: flex;flex-direction: column;">
			<div class="conten" style="position: relative;padding: 0 20px">
				<div class="top flex jc-between ai-center ">
					<div class="left ml-10 flex">
						<!-- <span class="mr-10  entName">
							<i class="el-icon-s-fold"></i>
							{{ activeName }}
						</span> -->
						<!-- <el-popover placement="bottom" width="400" v-model="visible">
							<el-tree
								:data="treeData"
								ref="tree"
								show-checkbox
								node-key="id"
								default-expand-all
								:props="defaultProps"
								style="max-height: 300px;overflow: auto;"
							></el-tree>
							<div class="treeFoot" style="float:right;margin-top: 10px;">
								<el-button @click="visible = false">取消</el-button>
								<el-button type="primary" @click="getTreeList">确定</el-button>
							</div>
							<el-button slot="reference" style="background: #FFFFFF;border-radius: 4px;border: 1px solid #4A99F6;color: #4A99F6;">查看设备</el-button>
						</el-popover> -->

						<el-checkbox-group v-model="checkList" @change="checkChange" class="ml-20  checkBox">
							<el-checkbox label="0">正常设备</el-checkbox>
							<el-checkbox label="1">异常设备</el-checkbox>
						</el-checkbox-group>
					</div>

					<div class="flex ai-center">
						<div class="ml-30 mr-20">
							<span class="g">
								<i class="el-icon-warning"></i>
								正常
							</span>
							<span class="b ml-10">
								<i class="el-icon-warning"></i>
								警告
							</span>
							<span class="f mx-10">
								<i class="el-icon-warning"></i>
								报警
							</span>
							<span class="r mx-10">
								<i class="el-icon-warning"></i>
								危险
							</span>
						</div>
						<el-button @click="setting" v-hasPerm="'1554311103218061312'">设置</el-button>
					</div>
				</div>
				<div class="table">
					<div class="thead flex">
						<div class=" pl-10" style="width:250px;">单体设备</div>
						<div class="" style="width:160px;">最近报警时间</div>
						<div class="flex-1 ">报警描述</div>
						<div class="flex-1">设备树</div>
						<div class="" style="width: 100px;">处理状态</div>
						<div class="" style="width: 250px;">操作</div>
					</div>
				</div>
			</div>
			<div class="conten" style="flex: 1; position: relative;padding: 0 20px">
				<div class="table" v-loading="loading">
					<div :id="'id' + item.id" :class="item.id == handleId ? 'xolor' : ''" class="tableBody"
						v-for="(item, index) in tableData" :key="index">
						<div class="tbody">
							<div class="title flex pos-rel" @click="show(item)">
								<div class="jc-start ai-center title-item-first" :title="item.name">
									<img :src="imgChange(item)"
										style="width: 20px;height: 20px;margin:0 4px 0 7px;vertical-align: middle;" />
									<span style="vertical-align: middle;">{{ item.name }}</span>
								</div>
								<div class=" h40 jc-start ai-center title-item" :title="item.time"
									style="width: 160px;">{{ item.time }}</div>
								<div class="  flex-1 h40 jc-start ai-center title-item"
									:title="item.comm ? item.comm : '暂无单体描述'">
									{{ item.comm ? item.comm : '暂无单体描述' }}
								</div>
								<div class="  flex-1 h40 jc-start ai-center title-item" :title="item.root">
									{{ item.path }}
								</div>
								<div class=" h40 jc-start ai-center title-item" :title="item.stat"
									style="width: 100px;">{{ item.stat }}</div>
								<div class="flex text-color h40 jc-start ai-center  title-item-btn"
									style="width: 250px;padding-right: 8px;">
									<!-- <span class="flex-1 ml-10" @click="getHistory(item)" >历史</span> -->
									<div class="flex-1 text-center" @click="getDchats(item)">3D</div>
									<div class="flex-1 text-center" @click="getDispose(item)">处理</div>
									<div class="flex-1 text-center" @click="getMaintain(item)">维修</div>
									<div class="flex-1 text-center" @click="getHelp(item)">求助</div>
									<div class="flex-1 text-center" @click="getLog(item)">日志</div>
								</div>
							</div>

							<div class="cedian">
								<el-row :gutter="10">
									<el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="4" v-for="(i, id) in item.children"
										:key="id" style="min-height: 180px;">
										<sm-card isCustomHeader noPadding class="cedian-item">
											<div slot="header" class="cedian-item-header">
												<img :src="Tubiao(i, imgList)" />
												<el-tooltip v-if="i.stat == 0" class="item" effect="light"
													content="采集数据异常!" placement="top-start">
													<i class="el-icon-warning-outline"
														style="margin-left:3px; font-size: 18px;color:#ff5500;cursor: help;"></i>
												</el-tooltip>
												<span>{{ i.name }}</span>
											</div>
											<div slot="body" class="cedian-item-body">
												<div v-for="(j, jd) in i.children" class="cedian-info"
													@click="getChats(j, item.unit_id)" :key="jd">
													<span class="cedian-key">{{ j.name }}</span>
													<div class="cedian-val">
														<span class="cedian-valNum">{{ j.num }}</span>
														<span class="cedian-valUnit">{{ j.unit }}</span>
													</div>
													<span class="cedian-status"><img
															src="@assets/osImg/pointer/ping.png" /></span>
												</div>
											</div>
										</sm-card>
									</el-col>
								</el-row>
							</div>
						</div>
					</div>

					<div class="noneData" style="height: 500px;position: relative; " v-if="tableData.length == 0">
						<img src="@assets/osImg/noneData.png"
							style="width: 240px;position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);" />
						<div
							style="position:absolute;height: 20px;font-size: 14px;font-weight: 400;color: #8C8C8C;bottom:20%;left:50%;transform: translate(-50%,-50%);">
							暂无相关内容
						</div>
					</div>
				</div>

				<!-- <div style="
				font-size: 14px;
				color: #8c8c8c;float: right;">技术支持：上海朋禾智能科技有限公司 </div> -->
			</div>
		</el-main>

		<chats ref="chats" :row="tbRow" :rows="tbRows" @update-value="closeChat"></chats>
		<threeDchats ref="threeDchats" :row="thRow"></threeDchats>
		<history ref="history" :row="lsRow"></history>
		<dispose ref="dispose" :row="clRow"></dispose>
		<maintain ref="maintain" :row="wxRow"></maintain>
		<help ref="help" :row="qzRow"></help>
		<log ref="log" :row="rzRow"></log>
		<setting :isShow.sync="showSettingDialog" :dialogObj="settingObj" :entId="ent_id"></setting>
		<!-- </el-container> -->
	</div>
</template>

<script>
	import {
		imgList88,
		setTubiao
	} from '@assets/js/screen.js';
	import caches from '@cache/cache.js';
	import chats from '../component/chats.vue';
	import threeDchats from '../component/threeDchats.vue';
	import history from '../component/history.vue';
	import dispose from '../component/dispose.vue';
	import maintain from '../component/maintain.vue';
	import help from '../component/help.vue';
	import log from '../component/log.vue';
	import setting from './setting.vue';
	// import pageHeader from "@c/pageHeader.vue"
	import smCard from '@c/smCard.vue';
	var token = caches.token.get();
	export default {
		props: ['activeList'],
		data() {
			return {
				activeId: '',
				activeName: '',
				list: [],
				companyName: '',
				selectData: [],
				tbRow: null,
				tbRows: null,
				lsRow: null,
				thRow: null,
				clRow: null,
				wxRow: null,
				qzRow: null,
				rzRow: null,
				tableData: [],
				treeData: [],
				from: {},
				ent_id: '',
				visible: false,
				checkList: ['0', '1'],
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				loading: false,
				t: null,
				lineId: '',
				typeId: '',
				url: '',
				activeTab: '1',
				showSettingDialog: false,
				settingObj: {},
				imgList: imgList88,
				drawer: false,
				handleId: '',
				// monitorType: "1"
				showData: [] //当前选中节点
			};
		},
		components: {
			chats,
			threeDchats,
			history,
			dispose,
			maintain,
			help,
			log,
			// pageHeader,
			smCard,
			setting
		},
		created() {
			this.getList();
		},
		mounted() {
			this.t = setInterval(this.getTableList, 60000);
			this.Bus.$on('asideChange', param => {
				this.drawer = true;
			});
		},
		beforeDestroy() {
			clearInterval(this.t);
		},
		updated() {
			window.onmessage = event => {
				if (!event.data.data) return;
				this.data = event.data.data;
				//进入3D
				switch (this.data.type) {
					// case 0:
					// 	this.url =
					// 		'/html/line.html?token=' + token + '&lineId=' + this.lineId + '&typeId=' + this.typeId + '&url=' + this.baseURL + '&imgUrl=' + this.photoURL;
					// 	break;
					case 1:
						//进入单体
						this.url =
							'/html/index.html?token=' +
							token +
							'&unitId=' +
							this.data.unitId +
							'&typeId=' +
							this.data.typeId +
							'&url=' +
							this.baseURL +
							'&imgUrl=' +
							this.photoURL;
						break;
					case 3:
						//单体进图谱
						this.getChats(this.data.row, this.data.rows);
						break;
						// case 4:
						// 	this.url =
						// 		'/html/line.html?token=' + token + '&lineId=' + this.lineId + '&typeId=' + this.typeId + '&url=' + this.baseURL + '&imgUrl=' + this.photoURL;
						// 	break;
					case 1111:
						let routeData = this.$router.resolve({
							name: 'shopDetails',
							query: {
								id: this.data.goodsId,
								skuId: this.data.goodsSkuId
							}
						});
						window.open(routeData.href, '_blank');
						break;
					case 2222:
						let routeData2 = this.$router.resolve({
							name: 'leaseDetails',
							query: {
								id: this.data.rentId,
								skuId: this.data.rentSkuId
							}
						});
						window.open(routeData2.href, '_blank');
						break;
					case 3333:
						let routeData3 = this.$router.resolve({
							name: 'diagnosisExpert'
						});
						window.open(routeData3.href, '_blank');
						break;
				}
			};
		},
		methods: {
			handleNodeClick(data) {
				if (data.children.length > 0) return;
				this.handleLeft(data.id);
				this.handleId = data.id;
			},
			Tubiao(item, e) {
				return setTubiao(item, e);
			},
			//设置
			setting() {
				this.showSettingDialog = true;
			},
			//全屏
			fullScreen() {
				var url = {
					url: '/html/line.html?token=' +
						token +
						'&lineId=' +
						this.lineId +
						'&typeId=' +
						this.typeId +
						'&entId=' +
						this.ent_id +
						'&url=' +
						this.baseURL +
						'&imgUrl=' +
						this.photoURL
				};
				this.$refs.threeDchats.dialogVisible = true;
				this.$refs.threeDchats.fullscreen = true;
				this.thRow = url;
			},
			//重置tab栏选择第一个
			resetTabSelected() {
				if (this.lineId == '') {
					this.activeTab = '2';
				} else {
					this.activeTab = this.monitorType;
				}
			},
			//获取监控模式
			// getMonitorMode() {
			// 	this.$get("/user-api/user-base/monitor/type", null).then((res) => {
			// 		if (res.code == 1000) {
			// 			this.monitorType = res.data.monitorType + "";
			// 		} else {
			// 			this.$message.error(res.msg);
			// 		}
			// 	});
			// },
			removeSelectedTopMenu() {
				this.$nextTick(() => {
					$('.topMenu .el-menu-item').removeClass('is-active');
				});
			},
			colorChange(val) {
				const borderStyles = {
				    '-1': 'gBorder',
				    '0': 'gBorder',
				    '1': 'bBorder',
				    '2': 'fBorder',
				    '3': 'rBorder'
				};
				
				return borderStyles[val.alarm.toString()];

			},
			imgChange(val) {

				const alarmImages = {
					'-1': require('@/assets/osImg/icon/jingbao-1.png'),
					'0': require('@/assets/osImg/icon/jingbao0.png'),
					'1': require('@/assets/osImg/icon/jingbao1.gif'),
					'2': require('@/assets/osImg/icon/jingbao2.gif'),
					'3': require('@/assets/osImg/icon/jingbao3.gif')
				};

				return alarmImages[val.alarm.toString()];
			},
			// 选择框
			checkChange(val) {
				this.from = {
					ent_id: this.ent_id,
					alarm: val[0]
				};
				if (val.length == 2 || val.length == 0) {
					this.from = {
						ent_id: this.ent_id
					};
				}
				this.getTableList();
			},
			// 跳转
			toHome() {
				this.$router.push('/platform');
			},
			// 选择设备树
			getTreeList() {
				this.visible = false;
				let unit_ids = this.$refs.tree.getCheckedKeys().concat(this.$refs.tree.getHalfCheckedKeys());
				this.from = {
					ent_id: this.ent_id,
					unit_ids: unit_ids.join(',')
				};
				this.getTableList();
			},

			// 请求设备树
			getTree() {
				this.$get('/backend-api/eqp/set/meas/list', this.from).then(res => {
					if (res.code == 1000) {
						this.treeData = res.data;
						res.data.forEach(item => {
							this.seleteTree(item);
						});
						this.$nextTick(() => {
							if (this.$refs.tree) {
								this.$refs.tree.setCheckedNodes(this.treeData);
							}
						});
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			seleteTree(item) {
				if (item.children && item.children.length > 0) {
					item.children.forEach(e => {
						this.seleteTree(e);
						if (e.type_2 == 50) {
							this.showData.push(e.id);
						}
					});
				}
			},
			// 获取侧边栏
			getList() {
				this.$get('/backend-api/web/monitor/ent', {
					name: this.companyName
				}).then(res => {
					if (res.code == 1000) {
						this.list = res.data;
						this.activeId = '0';
						this.activeName = this.list[0].abbr;
						this.from = {
							ent_id: this.list[0].id
						};
						(this.ent_id = this.list[0].id), this.getTableList();
						this.getTree();
						// this.getLineID(this.ent_id);
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			// 选中侧边栏
			handleChange(val) {
				this.activeName = this.list[val].abbr;
				this.from = {
					ent_id: this.list[val].id
				};
				(this.ent_id = this.list[val].id), this.getTableList();
				this.getTree();
				this.getLineID(this.ent_id);
			},
			//获取整线id
			getLineID(entId) {
				this.$get('/backend-api/web/monitor/lineIds', {
					ent_id: entId
				}).then(res => {
					if (res.code == 1000) {
						this.lineId = res.data.line_id;
						this.typeId = res.data.pc_id;
						this.loading = false;
						this.resetTabSelected();
						this.url =
							'/html/line.html?token=' +
							token +
							'&lineId=' +
							this.lineId +
							'&typeId=' +
							this.typeId +
							'&entId=' +
							this.ent_id +
							'&url=' +
							this.baseURL +
							'&imgUrl=' +
							this.photoURL;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			// 获取列表页数据
			getTableList() {
				this.loading = true;
				this.$get('/backend-api/web/monitor/list', this.from).then(res => {
					if (res.code == 1000) {
						this.tableData = res.data;
						this.loading = false;
					} else {
						this.loading = false;
						this.$message.error(res.msg);
					}
				});
			},

			// 打开图表弹框
			getChats(j, rows) {
				const typeMapping = {
				    1000: 0,
				    1001: 1,
				    1002: 2,
				    1003: 1003,
				    1004: 4
				};
				
				if (typeMapping.hasOwnProperty(j.type)) {
				    j.type = typeMapping[j.type];
				}

				this.Bus.$emit('clickDanti', j);
			},
			//关闭图表页面
			closeChat(newValue) {
				this.tbRow = null
			},

			//打开历史弹框
			getHistory(item) {
				this.$refs.history.dialogVisible = true;
				this.lsRow = item;
			},
			//打开3D弹框
			openThree() {
				var url = {
					url: '/html/line.html?token=' + token + '&lineId=' + this.lineId + '&entId=' + this.ent_id +
						'&url=' + this.baseURL + '&imgUrl=' + this.photoURL
				};
				this.$refs.threeDchats.dialogVisible = true;
				this.$refs.threeDchats.fullscreen = true;
				this.thRow = url;
			},
			getDchats(item) {
				if (item.type_id && item.type_id != '') {
					let fileUrl = ''
					if (item.isType == 1) {
						fileUrl = '/html/index.html'
					} else {
						fileUrl = '/html/indexOld.html'
					}

					var url = {
						url: fileUrl + '?token=' + token + '&unitId=' + item.unit_id + '&typeId=' + item.type_id +
							'&url=' + this.baseURL + '&imgUrl=' + this.photoURL
					};
					this.$refs.threeDchats.dialogVisible = true;
					this.$refs.threeDchats.fullscreen = true;
					this.thRow = Object.assign(item, url);
				} else {
					this.$message.warning('3D模型制作中');
				}
			},
			// 打开处理弹框
			getDispose(item) {
				this.$refs.dispose.dialogVisible = true;
				this.clRow = item;
			},
			// 打开维修弹框
			getMaintain(item) {
				this.$refs.maintain.dialogVisible = true;
				this.wxRow = item;
			},
			// 打开求助弹框
			getHelp(item) {
				this.$refs.help.dialogVisible = true;
				this.qzRow = item;
			},
			// 打开日志弹框
			getLog(item) {
				this.$refs.log.dialogVisible = true;
				this.rzRow = item;
			},
			// 列表显示隐藏
			show(item) {
				item.show = !item.show;
			},
			// 点击导航菜单，页面滚动到指定位置
			handleLeft(id) {
				let ids = this.$el.querySelector(`#id${id}`);
				if (ids) {
					ids.scrollIntoView({
						behavior: 'smooth', // 平滑过渡
						block: 'start' // 上边框与视窗顶部平齐。默认值
					});
					this.drawer = false;
				}
			}
		}
	};
</script>

<style lang="scss">
	.monitoring {
		position: relative;
		background-color: #f5f5f5;
		height: 100%;

		.drawerBox {
			position: absolute;
			left: 0;
			top: 0;
			background-color: #ffffff;
			width: 320px;
			height: 100%;
			z-index: 999;
			box-shadow: 5px 10px 10px 5px #bfbfbf;

			.drawerBox-top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 16px;
				padding: 15px 15px 0 15px;
				height: 3%;
			}

			.drawerBox-top-icon {
				transform: scale(1.5);
				// padding: 5px;
				cursor: pointer;
			}

			.treeBox {
				padding: 15px;
				height: 97%;
				overflow-y: auto;
			}
		}

		.cedian {
			padding: 12px;
			background-color: rgba(240, 240, 240, 1);

			.el-card__header {
				padding: 11px !important;
			}

			.is-hover-shadow:focus,
			.is-hover-shadow:hover {
				-webkit-box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
				box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
			}

			.cedian-item {
				font-size: 13px;
				border-color: rgba(217, 217, 217, 1);

				.cedian-item-header {
					position: relative;

					img {
						height: 20px;
						width: 20px;
						margin-right: 5px;
						vertical-align: top;
					}

					>i {
						vertical-align: middle;
					}

					>span {
						vertical-align: top;
						line-height: 20px;
					}

					>.header-right {
						display: inline-block;
						position: absolute;
						right: 0;
						top: -4px;
					}
				}

				.cedian-item-body {
					position: relative;

					.cedian-info {
						display: flex;
						padding: 7px 12px;
						cursor: pointer;
					}

					.cedian-info:not(:last-child) {
						border-bottom: 1px solid rgba(221, 221, 221, 1);
					}

					.cedian-key {
						flex: 2;
						color: rgba(140, 140, 140, 1);
					}

					.cedian-val {
						flex: 3;

						.cedian-valNum {
							color: #595959;
							font-weight: 400;
							font-size: 13px;
						}

						.cedian-valUnit {
							font-size: 10px;
							color: #8c8c8c;
							font-weight: 300;
						}
					}
				}

				.cedian-status {
					flex: 1;

					img {
						float: right;
					}
				}
			}
		}

		.textColor {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8c8c8c;
		}

		.fullScreenBtn {
			width: 70px;
			height: 40px;
			background: #d8d8d8;
			border-radius: 4px;
			border: 1px solid #979797;
			text-align: center;
			font-size: 14px;
			font-weight: 400;
			color: #333333;
			line-height: 40px;
			cursor: pointer;
			position: absolute;
			right: 46px;
			top: 223px;
		}

		.el-aside {
			border-right: 1px solid #f5f5f5;
			box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

			.companySeclet {
				height: 60px;

				.el-input__inner {
					background-color: rgb(38, 47, 57);
					border-color: rgba(125, 125, 125, 1);
				}

				.el-input-group__append,
				.el-input-group__prepend {
					background-color: #58a1f6;
					border-color: rgba(125, 125, 125, 1);
					color: #fff;
				}
			}
		}

		.indexTop {
			height: 60px;
			background-color: #fff;
		}

		.el-menu {
			border: none;
		}

		.el-main {
			padding: 0;
		}

		.tableBody {
			border: 1px solid #dfdfdf;
			border-top: none;
			margin-top: 10px;
		}

		.conten {
			margin: 20px 12px 0 12px;
			background-color: #fff;
			overflow: auto;

			.el-tabs__content {
				height: 100%;
			}

			.top {
				height: 50px;
				background-color: #fff;

				.left {
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
				}

				.entName {
					font-size: 16px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #595959;

					i {
						color: #4a99f6;
						width: 16px;
						height: 16px;
						margin-right: 10px;
					}
				}

				.el-button {
					padding: 8px 12px;
					background-color: #4a99f6;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #ffffff;
				}
			}

			.table {
				.thead {
					height: 40px;
					line-height: 40px;
					font-weight: bold;
					overflow: hidden;
					font-size: 14px;
					font-weight: 500;
					font-family: PingFangSC-Medium, PingFang SC;
					color: #595959;
					background-color: #f5f5f5;
					border-radius: 4px;
					border: 1px solid #d9d9d9;
					margin-bottom: 12px;
				}

				.tbody {
					box-sizing: border-box;
					color: #000;

					div {
						box-sizing: border-box;
					}

					.title {
						height: 40px;
						font-size: 14px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: bold;
						color: #333333;
						border-top: 1px solid #dfdfdf;

						.title-item-first {
							padding-left: 7px;
							font-weight: 400;
							color: #262626;
							width: 250px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							word-break: break-all;
							line-height: 35px;
						}

						.title-item {
							font-weight: 400;
							color: #595959;
							font-size: 12px;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							word-break: break-all;
							line-height: 40px;
							padding-left: 10px;
						}

						.title-item-btn div {
							font-size: 14px;
							font-weight: 400;
							font-weight: 400;
							color: #4a99f6;
							cursor: pointer;
						}

						div {
							border-right: 1px solid #dfdfdf;
						}

						div:last-child {
							border-right: none;
						}

						div:first-child {
							padding-left: 0;
						}

						span:hover {
							background: rgba(255, 255, 255, 0.4);
						}
					}

					.showBox {}

					.body {
						width: 100%;
						height: 54px;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #333333;
						background-color: #fff;
						border-top: 1px solid #dfdfdf;
						border-bottom: 1px solid #dfdfdf;

						div {
							border-right: 1px solid #dfdfdf;
							padding-left: 10px;
						}

						div:last-child {
							border-right: none;
						}

						img {
							width: 24px;
							height: 24px;
							margin-left: 14px;
						}
					}

					.btn {
						height: 54px;
						line-height: 54px;
						font-size: 14px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #262626;

						button {
							height: 30px;
							line-height: 30px;
							text-align: center;
							padding: 0 8px;
							cursor: pointer;
						}

						div {
							font-size: 14px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: bold;
							color: #262626;
						}
					}
				}
			}
		}

		.titleLeftBg {
			position: absolute;
			left: 0;
			top: 0;
			width: 4px;
			height: 40px;
		}

		.companysMenu {

			.el-menu-item.is-active,
			.el-menu-item:hover {
				background-color: #1c232a !important;
			}
		}

		.text-color {
			color: #4a99f6;
			font-weight: 500;
		}

		.intopColor {
			color: #d1d1d3;
			font-size: 16px;
		}

		.gBorder {
			background-color: #52c41a;
		}

		.bBorder {
			background-color: #ffdc34;
		}

		.fBorder {
			background-color: #e26617;
		}

		.rBorder {
			background-color: #d00606;
		}

		.g {
			color: #52c41a;
		}

		.b {
			color: #ffdc34;
		}

		.f {
			color: #e26617;
		}

		.r {
			color: #d00606;
		}

		.xolor {
			// background: #4a99f6;
			// color:#ffffff;
			border: 1px solid #4a99f6;
		}
	}

	.h54 {
		height: 54px;
	}

	.p-l-10 {
		padding-left: 10px;
	}

	.bottom-text {
		padding-top: 16px;
		padding-bottom: 13px;
		width: 100%;
		line-height: 20px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: rgba(0, 0, 0, 0.45);
	}

	.borderbottom0 {
		border-bottom: none !important;
	}

	.flex0_5 {
		flex: 0.5;
	}

	.flex1_5 {
		flex: 1.5;
	}

	.flex2_5 {
		flex: 2.5;
	}
</style>